import React, { useState, useEffect } from "react";
import kayImagePath from "../assets/kaymic.JPG";
import braImagePath from "../assets/bradguit.png";
import davImagePath from "../assets/davmic.jpg";
import chrImagePath from "../assets/chrismic.jpeg";
import { hover } from "@testing-library/user-event/dist/hover";

function AboutUsPage(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [screenWidth, setScreenWidth] = useState(props.screenWidth);
  const handleItemHover = (item,event) => {
    scrollToTop();
    setHoveredItem(item);
  };

  const handleItemLeave = () => {
    setHoveredItem(null);
  };
  useEffect(()=>{
    setScreenWidth(props.screenWidth);
  },[props.screenWidth])
  useEffect(()=>{
    if (kayImagePath != null && braImagePath != null && davImagePath != null && chrImagePath != null){
      setIsLoaded(true);
    }
  }, [kayImagePath, braImagePath,davImagePath,chrImagePath]);
  useEffect(() => {
    // Set isLoaded to true after the component has mounted
    setIsLoaded(true);
  }, []); // Empty dependency array ensures that this effect runs only once after mount
  function scrollToTop() {
    // Scroll to the top of the page with smooth transition
    const container = document.getElementsByClassName("about-us-container")[0];
    container.scrollTo({
      top: 0,
      behavior: "smooth"
    }); // For modern browsers
    
    document.getElementsByClassName("about-us-container")[0].scrollTo({
      top: 0,
      behavior: "smooth"
    }); // For older browsers
  }
  return (
    <div className={`about-us-container ${screenWidth < 800 ? 'mobile' : ''}`}>
      <h1 className={`modal-header ${isLoaded ? 'loaded' : ''}`}>ABOUT US</h1>
      {hoveredItem === "dave" && (
        <div className="floating-description"
        style={{position: `${screenWidth > 800 ? "absolute" : "static"}`, left: `${screenWidth > 800 ? '-39%' : ""}`, top: '15%', width:"50vh", 
        opacity: hoveredItem ? 1 : 0, // Show or hide based on hoveredItem,
        transition: "ease-in-out",
        transitionDuration: "1s"
        }}>
        <p className="grid-desc">
              Dave Langston is an artist from Queens, New York. Songwriter, Screenplay writer , Poet- rapper, and singer. Graduating from Suny Purchase for theatre, Dave always had a niche for creating music , starting at 12 while writing for high school bands while in middle school , to working alongside artists in highschool such as designer artist “ozzy oz da vyrus” 
              While in college, 
              Dave has written for artists such as Dianna Lopez, worked alongside Julia Wolf,
              along with executive producing
              “Soul searching” EP by Dianna Lopez  & 
              “The beginnings” EP by Bairi . 
              Overall , Dave loves to create in general and is open and willing to work with everyone hence the creation of NFG
              </p>            
            </div>
          )}
      {hoveredItem === "kay" && (
        <div className="floating-description"
        style={{position: `${screenWidth > 800 ? "absolute" : "static"}`, left: `${screenWidth > 800 ? '-39%' : ""}`, top: '15%', width:`${screenWidth > 800 ? '500px' : ""}`, 
        opacity: hoveredItem ? 1 : 0, // Show or hide based on hoveredItem,
        transition: "ease-in-out",
        transitionDuration: "1s"
        }}>
        <p className={`grid-desc ${hoveredItem==="kay" ? 'loaded' : ''}`}>
        Kaylee is a Brooklyn based singer, writer, & producer. 
        She loves cooking breakfast & being alone. 
        She specializes in apologizing for being a jerk sometimes & then taking it back when you're a meanie in return. 
        She was once in the top .001% of Ariana Grande listeners & will absolutely let that define her.</p>        
        </div>
      )}
      {hoveredItem === "brad" && (
        <div className="floating-description"
        style={{position: `${screenWidth > 800 ? "absolute" : "static"}`, left: `${screenWidth > 800 ? '-39%' : ""}`, top: '15%', width:`${screenWidth > 800 ? '500px' : ""}`, 
        opacity: hoveredItem ? 1 : 0, // Show or hide based on hoveredItem,
        transition: "ease-in-out",
        transitionDuration: "1s"
        }}>        <p className={`grid-desc ${hoveredItem==="brad" ? 'loaded' : ''}`}>
            Bradley CD is a singer-songwriter/producer working out of Brooklyn, New York. 
          Raised on semi-random CD choices from the local Library, Bradley’s influence are wide, but always gravitate towards the weird and flamboyant. 
          As a part of the New York music collective, NFG, Bradley has produced for singers, rappers, classical musicians and everything in between. He finds inspiration from bridging genres and creating ambitions songs with realistically too many switch ups.
          His latest project, Noise Between Static, is an experimental electronic album which explores musical mortality through samples of radio host grappling with their own shows ending. 
          His follow up, Thinking of Leaving, is arriving soon, and takes influence from artists like Kirin J Callinan and Car Seat Headrest.</p>    
            </div>
          )}
      {hoveredItem === "chris" && (
        <div className="floating-description"
        style={{position: `${screenWidth > 800 ? "absolute" : "static"}`, left: `${screenWidth > 800 ? '-39%' : ""}`, top: '15%', width:`${screenWidth > 800 ? '500px' : ""}`, 
        opacity: hoveredItem ? 1 : 0, // Show or hide based on hoveredItem,
        transition: "ease-in-out",
        transitionDuration: "1s"
        }}>        <p className={`grid-desc ${hoveredItem==="chris" ? 'loaded' : ''}`}>
        Say June is a producer/instrumentalist from New York. His music tastes range from classical to EDM to Old-school K-pop to New-school Hip-Hop. 
        Having produced artists like Griffin Coulter, Sarah Manzo, Bebiisan, and Kristen Victorias, his focus has grown from audio engineering to sound design to artistic vision.
        A core member of NFG, Say June's work includes mixing and production work alongside each of the members of NFG.
        Though he's far away physically for now, Say June will NEVER be TOO FAR. 
        Recent releases include Lavender (featuring Dave Langston, Jaigo, and Bebiisan), as well as an extremely intricate cover of Blessing by Yoasobi, co-produced with Bebiisan. 
        In his free time, Say June likes playing video games, designing this website, and cooking.
      </p>    
        </div>
      )}
      <div className="grid-container" style={{display: `${screenWidth < 800 ? 'flow' : 'grid'}`}}>
      <div className={`grid-item ${isLoaded ? 'loaded' : ''}`} 
          style={{ backgroundImage: `url(${davImagePath})` , backgroundBlendMode: "darken", cursor:"pointer", backgroundSize: "cover"}}
          onClick={(e) => handleItemHover("dave")}>
          <h2 className="dave-langston">DAVE LANGSTON</h2>
          
          
        </div>
        <div className={`grid-item ${isLoaded ? 'loaded' : ''}`} 
        style={{ backgroundImage: `url(${kayImagePath})` , backgroundBlendMode: "darken", backgroundSize: "cover"}}
        onClick={(e) => handleItemHover("kay")}>
          <h2 className="kaylee">KAYLEE</h2>
          
          
        </div>
        <div className={`grid-item ${isLoaded ? 'loaded' : ''}`} 
        style={{ backgroundImage: `url(${braImagePath})` , backgroundBlendMode: "darken", backgroundSize: "cover"}}
        onClick={(e) => handleItemHover("brad")}>        
          <h2 className="bradley-cd">BRADLEY CD</h2>
          
          
        </div>
        <div className={`grid-item ${isLoaded ? 'loaded' : ''}`} 
        style={{ backgroundImage: `url(${chrImagePath})` , backgroundBlendMode: "darken", backgroundSize: "cover"}}
        onClick={(e) => handleItemHover("chris")}>
          <h2 className="say-june">SAY JUNE</h2>
          
          
        </div>
      </div>
      
    </div>
  );
}

export default AboutUsPage;
