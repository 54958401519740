import React, { useState, useEffect } from "react";
import MusicPage from "./MusicPage";
import ShowsPage from "./ShowsPage";
import AboutUsPage from "./AboutUsPage";
import ContactPage from "./ContactPage";
import Modal from "./Modal";
import { X } from "react-bootstrap-icons";

function Menyu(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [screenWidth, setScreenWidth] = useState(props.screenWidth);
  useEffect(()=>{
    setIsModalOpen(props.isModalOpen);
    if (!props.isModalOpen){
      console.log("closing")
      handleModalClose();
    }
  },[props.isModalOpen])

  useEffect(()=>{
    setScreenWidth(props.screenWidth);
  },[props.screenWidth])
  const handleModalOpen = (component) => {
    setActiveComponent(component);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setActiveComponent(null);
  };

  return (
    <div>
      <header className="mainHeader">
        <h1 className="nfg bigTitle">NFG</h1>
        <div className="menuContainer">
          <div>
            <a
              className="Music cell bigTitle"
              onClick={() => {
                props.clicked();
                handleModalOpen(<MusicPage />)}
              }
            >
              Music
            </a>
          </div>
          <div>
            <a
              className="Shows cell bigTitle"
              onClick={() => {
                props.clicked();
                handleModalOpen(<ShowsPage/>)}
              }
              >
              Shows
            </a>
          </div>
          <div>
            <a
              className="AboutUs cell bigTitle"
              onClick={() => {
                props.clicked();
                handleModalOpen(<AboutUsPage  screenWidth={screenWidth}/>)}
              }
              >
              About Us
            </a>
          </div>
          <div>
            <a
              className="Contact cell bigTitle"
              onClick={() =>{
                props.clicked();
                handleModalOpen(<ContactPage />)}
              }
              >
              Contact
            </a>
          </div>
        </div>
      </header>
      <div className={`modal ${isModalOpen ? "open" : ""} ${screenWidth<710 ? "mobile" : ""}`} >
        <div className="modal-content" >
          <button className="exit-button" onClick={handleModalClose}>
            <X size={50} color="#6D448A" enableBackground="true"/>
          </button>
          {activeComponent}
        </div>
      </div>
    </div>
  );
}

export default Menyu;
