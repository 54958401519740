import React, { useState, useEffect } from "react";

function MusicPage() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Set isLoaded to true after the component has mounted
    setIsLoaded(true);
    
  }, []); // Empty dependency array ensures that this effect runs only once after mount

  return (
    <div className="music-page-container">
      {/* Adjusted height to a specific value */}
      <h1 className={`modal-header ${isLoaded ? 'loaded' : ''}`}>MUSIC</h1>
      <p className="modal-description">
        Our latest album is out! We like it! Listen on Spotify
      </p>
      <div className="iframe-container">
        <iframe
          style={{ borderRadius: "12px", textAlign:"center", width:"60vw" }}
          src="https://open.spotify.com/embed/artist/4G2S4wiKhtsgV7Yv1MHjf1?utm_source=generator&theme=0"
          height="500px"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
      <p className="modal-description">and YouTube!</p>
      <div className="iframe-container">
        <iframe
        style={{width:"60vw"}}
          height="500px"
          src="https://www.youtube.com/embed/videoseries?si=HpmhyW9DsP2S8FCS&amp;list=OLAK5uy_n6fY95szgmJljfeluOp_tg8KFnwxBWnEU"
          title="YouTube"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default MusicPage;
