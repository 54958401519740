import { Canvas } from 'react-three-fiber';
import React, { useState, useRef, useEffect } from 'react';
import Cube from './Cube';
import AudioPlayer from 'react-audio-player'; // Import the AudioPlayer component
import LastNight from '../assets/lastnight.mp3';

export default function MyCanvas({didClick,handleModalClose}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [didClickState, setDidClickState] = useState(false);
  const lineRef = useRef();
  const audioRef = useRef(null);
  useEffect(() => {
    const canvasContainer = document.querySelector(".canvasContainer");
    if (canvasContainer) {
      canvasContainer.style.opacity = 1; /* Set opacity to 1 on component mount */
    }
  }, []);

  useEffect(() => {
    setDidClickState(didClick);
  }, [didClick]);

  const onAudioProcess = () => {
    // Audio processing logic (if needed)
  };

  const togglePlay = (e) => {
    if (e) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };
  const handleClick = () => {
    // Call the handleModalClose function passed from App.js
    // This will close the modal in Menyu component
    handleModalClose();
  };
  return (
    <div className='canvasContainer' onClick={handleClick}>
      <Canvas>
        {didClick && <Cube position={[2.4, 2, 1]} texture="nfg" shape="box" togglePlay={togglePlay} />}
      </Canvas>
      <audio ref={audioRef} src={LastNight} />
      <p className="footer">Copyright © N.F.G. Records 2024.</p>
    </div>
  );
}
