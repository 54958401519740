import logo from './logo.svg';
import './App.css';
import Menyu from './components/Menyu';
import MyCanvas from './components/MyCanvas';
import React, {useState, useEffect} from "react";
function App() {
  const [didClick,setDidClick] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  useEffect(()=>{
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);
  return (
    <div className="App">
      <Menyu clicked={() => setDidClick(true)} isModalOpen={isModalOpen} screenWidth={screenWidth}/>
      <MyCanvas didClick={didClick} handleModalClose={handleModalClose}/>
    </div>
  );
}

export default App;
