import React, { useState, useEffect } from "react";

function ContactPage(){
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      // Set isLoaded to true after the component has mounted
      setIsLoaded(true);
    }, []); // Empty dependency array ensures that this effect runs only once after mount
  
    return (
        <div>
            <h1 className={`modal-header ${isLoaded ? 'loaded' : ''}`}>CONTACT</h1>
            <p className="modal-description">
  For booking and inquiries, email us at <a href="mailto:nfgnycofficial@gmail.com">nfgnycofficial@gmail.com</a>
</p>
        </div>
    )
}

export default ContactPage;