import React, { useState } from "react";
import show1Image from "../assets/pianosposter.PNG"; // Import your images
import show2Image from "../assets/delanceyposter.png";
import show3Image from "../assets/theroostposter.JPG";
import show4Image from "../assets/wondervilleposter1.jpeg";
import show5Image from "../assets/wondervilleposter2.jpg";
import show6Image from "../assets/wondervilleposter3.jpg";

function ShowsPage() {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    // Set isLoaded to true once the image is loaded
    setIsLoaded(true);
  };

  const prevShowImages = [show1Image, show2Image, show3Image, show4Image, show5Image, show6Image];
  const upcomingShowImages = [];
  const prevShowDescriptions = [
    "@Pianos NYC. Our debut show! Took this picture after an open mic at @TheRoseNYC",
    "@TheDelancey NYC. Photo from our trip to Massachussetts.",
    "The Roost Open Mic. More of these to come!",
    "@Wonderville Huuuuge show and super memorable",
    "@Wonderville Slumber Party on 4/12/2024! Free show that knocked our socks off.",
    "@Wonderville Block Party on 7/27/2024! 10$ tickets worth every penny.",
  ];
  const upcomingShowDescriptions = [
  ];
  return (
    <div className="shows-page-container">
      <h1 className={`modal-header ${isLoaded ? "loaded" : ""}`}>
        UPCOMING SHOWS
      </h1>
      <div className="show-container">
        {upcomingShowImages.map(
          (
            image,
            index // todo: click a poster to see full image
          ) => (
            <div key={index} className="show-item">
              <img
                src={image}
                alt={`Show ${index + 1}`}
                className="blurred-image"
                style={{ width: "100%", height: "auto" }} // Set width to 100% and height to auto for scaling
                onLoad={handleImageLoad}
              />
              <div className="description-box">
                <p className="img-description">
                  {upcomingShowDescriptions[index]}
                </p>
              </div>
            </div>
          )
        )}
      </div>
      <h1 className={`modal-header ${isLoaded ? "loaded" : ""}`}>
        Previous SHOWS
      </h1>
      <div className="show-container">
        {prevShowImages.map(
          (
            image,
            index // todo: click a poster to see full image
          ) => (
            <div key={index} className="prev-show-item">
              <img
                src={image}
                alt={`Show ${index + 1}`}
                className="blurred-image"
                style={{ width: "100%", height: "auto" }} // Set width to 100% and height to auto for scaling
                onLoad={handleImageLoad}
              />
              <div className="description-box">
                <p className="img-description">{prevShowDescriptions[index]}</p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default ShowsPage;
