import { useState,useRef } from 'react';
import { Canvas, useFrame, useLoader } from 'react-three-fiber';
import * as THREE from 'three';
import { Color } from 'three';
import nfgTexture from '../assets/nfg.png';


export default function Cube(props) {
  const meshRef = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let directionUp = true;
  let directionRight = true;
  let theTexture;
  let theShape;

  if (props.texture === "nfg"){
    theTexture = nfgTexture;
  }
  if(props.shape === "box"){
    theShape = <boxGeometry args={[1,1,1]} />
  }
  if (props.shape==="sphere"){
    theShape = <sphereGeometry args={[0.5]} />
  }
  const texture = useLoader(THREE.TextureLoader, theTexture);
  
  useFrame(() => {
    if (!isHovered){
      if (directionUp){
        if (meshRef.current.position.y > 3){
          directionUp = !directionUp;
        }
        meshRef.current.position.y +=0.007;
      }else{
        if (meshRef.current.position.y < -2.5){
          directionUp = !directionUp;
        }
        meshRef.current.position.y -=0.007;
      }
      if (directionRight){
        if (meshRef.current.position.x >4){
          directionRight = !directionRight;
        }
        meshRef.current.position.x +=0.007;
      } else {
        if (meshRef.current.position.x < -4){
          directionRight = !directionRight;
        }
        meshRef.current.position.x -=0.007;
      }
      meshRef.current.rotation.x += 0.02;
      meshRef.current.rotation.y += 0.002;
    }
  });



  return (
    <>
      <ambientLight color="white"intensity={.4}/>
      <ambientLight intensity={.05}/>
      <directionalLight position={[0, 3, 0]} color="white" intensity={0.3}/>
      <mesh ref={meshRef} {...props}
        onPointerOver={(event) => {setIsHovered(true); props.togglePlay(true);}}
        onClick={(event) => {setIsHovered(!isHovered); props.togglePlay(!isHovered);}}
        onPointerOut={(event) => {setIsHovered(false);props.togglePlay(false);}}>
        {theShape}
        <meshStandardMaterial map={texture} />
      </mesh>
    </>
  );
}